@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-active-filter[data-v-5f35da56] {
  border: 1px solid #CCC;
  border-radius: 20px;
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  margin: 4px;
  cursor: default;
}
.ah-active-filter-icon-container[data-v-5f35da56] {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.ah-active-filter-icon[data-v-5f35da56] {
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -4px;
  left: -4px;
}
.ah-active-filter-label[data-v-5f35da56] {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  color: #555;
  line-height: 19px;
}
.ah-active-filter-close-icon[data-v-5f35da56] {
  margin-left: 4px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
