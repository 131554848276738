@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-filter-group-badge[data-v-43d1a254] {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f8;
  margin: 4px;
  padding: 12px 15px;
  border-radius: 22px;
  cursor: pointer;
}
.ah-filter-group-badge[data-v-43d1a254]:hover {
    background-color: #e8e8ed;
}
.ah-filter-group-badge.active[data-v-43d1a254] {
    background-color: #00234A;
}
.ah-filter-group-badge.active .ah-filter-group-badge-label[data-v-43d1a254] {
      color: white;
}
.ah-filter-group-badge.active[data-v-43d1a254]:hover {
      background-color: #002f64;
}
.ah-filter-group-badge-label[data-v-43d1a254] {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  color: #00234A;
  line-height: 20px;
  font-weight: normal;
  cursor: pointer;
}
.ah-filter-group-children-counter[data-v-43d1a254] {
  background-color: #FFAA64;
  color: #00234A;
  font-family: "Rubik", sans-serif;
  font-size: 12px;
  line-height: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ah-filter-group-children-counter[data-v-43d1a254], .ah-filter-group-plus-icon[data-v-43d1a254] {
  margin-left: 8px;
  width: 16px;
  height: 16px;
}
.ah-filter-group-plus-icon-container[data-v-43d1a254] {
  display: flex;
}
