@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-icon-link[data-v-3c3b9671] {
  text-decoration: none;
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
}
.ah-icon-link-img[data-v-3c3b9671] {
  max-width: 16px;
  max-height: 16px;
}
.ah-icon-link-label[data-v-3c3b9671] {
  color: #00234A;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  padding-left: 6px;
}
