.ah-drawer[data-v-2af40dcf] {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 330px;
  height: 100%;
  padding: 52px 24px;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0 60px 0 rgba(0, 35, 74, 0.25);
  z-index: 9999;
}
.ah-drawer-close-button[data-v-2af40dcf] {
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
}
