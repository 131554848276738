
.ah-card-species-icons[data-v-06aa4b1c] {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  background-color: white;
  border-bottom-left-radius: 5px;
  padding: 10px;
  border: 1px solid #DCDCE5;
  border-top: none;
  border-right: none;
}
.ah-card-species-icon[data-v-06aa4b1c] {
  max-height: 28px;
  max-width: 28px;
  margin: 0 2px;
}
