@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-filter-drawer[data-v-191912e7] {
  position: absolute;
  top: 0;
  right: 0;
  width: 330px;
  height: 100%;
  padding: 52px 24px;
  overflow-y: auto;
  box-sizing: border-box;
  box-shadow: 0 0 60px 0 rgba(0, 35, 74, 0.25);
}
.ah-filter-drawer-group[data-v-191912e7] {
  border: none;
  margin: 0 0 24px 0;
  padding: 0;
}
.ah-filter-drawer-group-label[data-v-191912e7] {
  font-family: "Rubik", sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: black;
  margin-bottom: 12px;
}
.ah-filter-drawer-group-filter[data-v-191912e7] {
  height: 23px;
  margin-bottom: 4px;
  font-family: "Rubik", sans-serif;
  font-size: 17px;
  color: #00234A;
}
.ah-filter-drawer-group-filter[data-v-191912e7], .ah-filter-drawer-group-filter input[data-v-191912e7], .ah-filter-drawer-group-filter label[data-v-191912e7] {
    cursor: pointer;
}
.ah-filter-drawer-submit-button[data-v-191912e7] {
  width: 100%;
  height: 44px;
  background-color: #00234A;
  color: #FFAA64;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
