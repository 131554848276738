@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-product-section[data-v-33d81354] {
  margin: 32px 0;
}
.ah-product-section-header[data-v-33d81354] {
  margin-bottom: 32px;
}
.ah-product-section-heading[data-v-33d81354] {
  font-family: "Rubik", sans-serif;
  font-size: 26px;
  color: #00234A;
}
.ah-product-section-items[data-v-33d81354] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -15px;
}
.ah-product-section-item[data-v-33d81354] {
  /*margin: 15px;*/
}
.ah-product-section-footer[data-v-33d81354] {
  margin-top: 30px;
}
.ah-product-section-expand-button[data-v-33d81354] {
  width: 100%;
  height: 50px;
  background-color: #00234A;
  color: #FFAA64;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
