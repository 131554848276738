.ah-product-card[data-v-45ae47be] {
  margin: 15px 0;
}
.ah-product-card-inner[data-v-45ae47be] {
  display: flex;
  flex-direction: column;
  /*width: 308px;*/
  box-shadow: 2px 2px 14px 0 rgba(0, 35, 74, 0.1);
  border-radius: 5px;
  height: 100%;
  margin: 0;
}
