
.ah-card-header[data-v-3d22f071] {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 200px;
}
.ah-card-header a[data-v-3d22f071] {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
}
.ah-card-header-image[data-v-3d22f071] {
  max-height: calc(100% - 16px);
}
