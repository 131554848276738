@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-product-card-body {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
}
.ah-product-card-category {
  color: #555;
  margin-bottom: 7.5px;
  font-family: "Rubik", sans-serif;
  font-size: 15px;
}
.ah-product-card-heading {
  color: #00234A;
  margin-bottom: 8px;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: 600;
}
.ah-product-card-excerpt {
  color: #00234A;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
}
