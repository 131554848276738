
.ah-product-card-bottom {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
}
.ah-product-card-footer {
  padding: 0 20px 24px 20px;
  display: flex;
  flex-direction: row;
}
.ah-product-card-link {
  margin-right: 16px;
}
