@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-search-bar[data-v-1d9c71dd] {
  position: relative;
  margin-bottom: 24px;
}
.ah-search-bar-input[data-v-1d9c71dd] {
  height: 48px;
  border: solid 1px #AEAEBA;
  border-radius: 24px;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  width: 100%;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 65px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  outline: none;
}
.ah-search-bar-button[data-v-1d9c71dd] {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  position: absolute;
  right: 8px;
  top: 0;
  cursor: pointer;
  outline: none;
}
