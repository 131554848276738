@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansAltBook.otf") format("opentype");
}
@font-face {
  font-family: GrotaSans;
  src: url("/Vendor/grotasans/Latinotype - GrotaSansRd-ExtraBold.otf") format("opentype");
  font-weight: 700;
}

/*#afd186*/
.ah-active-filters[data-v-e99ddce6] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px;
}
.ah-clear-filters[data-v-e99ddce6] {
  margin: 7px 0 7px 20px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  color: #00234A;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ah-clear-filters-label[data-v-e99ddce6] {
  margin-left: 6px;
}
